import { I18Keys } from './kyel.list'

export const translation: Record<I18Keys, string> = {
  [I18Keys.appName]: 'microdemocracies',
  [I18Keys.project]: 'microdemocracy',
  [I18Keys.startNow]: 'create a microdemocracy',
  [I18Keys.startNew]: 'create a microdemocracy',
  [I18Keys.back]: 'back',
  [I18Keys.next]: 'next',
  [I18Keys.prev]: 'prev',
  [I18Keys.review]: 'review',
  [I18Keys.accept]: 'accept',
  [I18Keys.create]: 'create',
  [I18Keys.openApp]: 'or open app',
  [I18Keys.invite]: 'invite',
  [I18Keys.inviteNew]: 'invite new',
  [I18Keys.listOf]: 'List of',
  [I18Keys.noProjects]: 'Your have not joined or started any microdemocracy yet.',
  [I18Keys.yourProjects]: `Your microdemocracies`,
  [I18Keys.carousel01]: 'Find something you know <Bold>needs</Bold> to change',
  [I18Keys.carousel01sub]: 'Dare others to change it, <Bold>together</Bold>',
  [I18Keys.carousel02]: 'Talk <Bold>without fear</Bold>',
  [I18Keys.carousel02sub]:
    'Post <Bold>anonymously</Bold> into the community board, but only if you are a member',
  [I18Keys.carousel03]: 'Amplify your <Bold>voice</Bold>',
  [I18Keys.carousel03sub]: 'Anonymously <Bold>back</Bold> the statements you agree with',
  [I18Keys.carousel04]: '<Bold>Guaranteed and unbreakable</Bold> anonymity',
  [I18Keys.carousel04sub]:
    '<Bold>No one</Bold>, not even us!, can break the anonymity of your statements, post or react on someones behalf, or add or remove members to the microdemocracy',
  [I18Keys.connectWallet]: 'Connecting your identity',
  [I18Keys.connectWalletBtn]: 'Sign in',
  [I18Keys.notFound]: 'not found',
  [I18Keys.connectWithEmail]: 'Connect with your email',
  [I18Keys.connectWithEmailBtn]: 'Signin with email',
  [I18Keys.accountReady]: 'Account Ready',
  [I18Keys.connect]: 'Connect',
  [I18Keys.loadingProject]: 'Loading microdemocracy details',
  [I18Keys.projectNotFound]: 'Project not found',
  [I18Keys.noStatements]: 'Nothing has been said yet',
  [I18Keys.communityVoice]: "Community's voice",
  [I18Keys.projectHeader]: 'microdemocracy for',
  [I18Keys.members]: 'members',
  [I18Keys.member]: 'member',
  [I18Keys.seeInviteMembers]: 'See/Invite Members',
  [I18Keys.propose]: 'propose',
  [I18Keys.proposeNew]: 'propose new',
  [I18Keys.statement]: 'statement',
  [I18Keys.proposeStatement]: 'Propose Statement',
  [I18Keys.proposeStatementBtn]: 'Propose Statement',
  [I18Keys.statementProposed]: 'Statement Proposed',
  [I18Keys.connectToPropose]: 'Connect to propose',
  [I18Keys.proposeInfo]:
    'Propose an anonymous statement and see how many members of the community back it. Only existing members will be able to support your statement. Make sure you invite them before posting.',
  [I18Keys.newStatement]: 'new statement',
  [I18Keys.sendingProposal]: 'sending anonymous proposal',
  [I18Keys.onlyMembersCanInvite]: 'Only existing members can invite new ones',
  [I18Keys.invitedBy]: 'invited by',
  [I18Keys.founder]: 'Founder',
  [I18Keys.accountInvalidated]: 'Account invalidated',
  [I18Keys.creatingProject]: 'Creating your microdemocracy',
  [I18Keys.examplesWho]:
    '<Bold>Good examples are sentences starting like:</Bold> "WANTS TO...",   "LIVES IN...", "STUDIES AT...", etc.',
  [I18Keys.wantsTo]: 'Wants to',
  [I18Keys.whoTitle]: 'This microdemocracy is for anyone who',
  [I18Keys.yourDetails]: 'Your details',
  [I18Keys.connectAccount]: 'Connect Account',
  [I18Keys.startA]: 'Start a',
  [I18Keys.tryoutMsg]:
    '<Bold>TRYOUT ONLY!</Bold> This app is currenty under development. Plase do not use it to create real communities just yet.',
  [I18Keys.IDNumber]: 'ID Number',
  [I18Keys.IDNumberLong]: 'ID Number (Last 4 digits)',
  [I18Keys.toJoinMsg]: 'To join, participants should give their',
  [I18Keys.toJoinMsg2]: 'Participants will be asked to provide',
  [I18Keys.selectMsgOpts]: 'Select one, none, or many of the options below',
  [I18Keys.nameAndLastName]: 'Name and Lastname',
  [I18Keys.hideList]: 'Hide List',
  [I18Keys.and]: 'and',
  [I18Keys.social]: 'Social',
  [I18Keys.account]: 'account',
  [I18Keys.choosePlatformsMsg]:
    'Chose the platform(s) account(s) that will be asked to participants',
  [I18Keys.firstName]: 'First Name',
  [I18Keys.lastName]: 'Last Name',
  [I18Keys.username]: 'username',
  [I18Keys.connecting]: 'Connecting',
  [I18Keys.canJoin]: 'Can join anyone who',
  [I18Keys.notWhoGiven]: 'It seems you forgot to include who can join',
  [I18Keys.anyoneWho]: 'For anyone who',
  [I18Keys.foundedCommunity]: 'founded the community',
  [I18Keys.joinOurMsg]: `Join our microdemocracy!`,
  [I18Keys.close]: 'Close',
  [I18Keys.shareToInvite]: 'Share to invite',
  [I18Keys.resettingMsg]:
    'Re-setting the link will invalidate the invitations sent with previous links',
  [I18Keys.reset]: 'Reset link',
  [I18Keys.resetting]: 'Resetting...',
  [I18Keys.shareLinkMsg]: 'Share link with new members or show them a QR they can scan.',
  [I18Keys.linkCopied]: 'link copied!',
  [I18Keys.shareLink]: 'share link',
  [I18Keys.showQr]: 'show QR',
  [I18Keys.scanQr]: 'scan QR',
  [I18Keys.cancel]: 'cancel',
  [I18Keys.inviteTitle]: 'Invite new member',
  [I18Keys.rememberInviteMsg]: 'Remember, this microdemocracy is for anyone who',
  [I18Keys.loadingProjects]: 'Loading your microdemocracies',
  [I18Keys.connectedAs]: 'Connected',
  [I18Keys.wallet]: 'Wallet',
  [I18Keys.owner]: 'Owner',
  [I18Keys.logout]: 'logout',
  [I18Keys.helpEditable]: 'Add links or basic styling by highlighting words',
  [I18Keys.details]: 'details',
  [I18Keys.options]: 'options',
  [I18Keys.waitingTx]: 'Waiting for transaction',
  [I18Keys.loading]: 'loading',
  [I18Keys.askJoin]: `I would like to join your microdemocracy!`,
  [I18Keys.you]: 'you',
  [I18Keys.orUseQR]: 'Or use and scan a QR code',
  [I18Keys.resetLink]: 'Reset link',
  [I18Keys.joinProject]: 'Join',
  [I18Keys.personalDetailsPre]: 'Personal',
  [I18Keys.personalDetailsMain]: 'Details',
  [I18Keys.reviewApplicationPre]: 'Review',
  [I18Keys.reviewApplicationMain]: 'your application',
  [I18Keys.applicationSentPre]: 'Application',
  [I18Keys.applicationSentMain]: 'done',
  [I18Keys.applicationReceived]:
    'Your application was received. Wait for it to be accepted by the person who invited you, or share the QR or the link below with an existing member and ask them to accept your application.',
  [I18Keys.projectSummary]: 'Summary of your microdemocracy',
  [I18Keys.your]: 'Your',
  [I18Keys.approveNew]: 'Approve new',
  [I18Keys.askSupport]: 'Please support my statement',
  [I18Keys.applicationAcceptedAs]: 'Member accepted as',
  [I18Keys.important]: 'Important!',
  [I18Keys.atLeastNMembers]:
    'There must be at least {{nMembers}} members to propose a statement',
  [I18Keys.canBackN]:
    'Only the existing {{nMembers}} members will be able to back this statement',
  [I18Keys.aStatementNeeds]:
    'A statement needs {{nLikes}} like more to appear on the microdemocracy home page',
  [I18Keys.maxStatementsPerPeriod]: 'Each member can only publish one statement per week',
  [I18Keys.ownedBy]: 'Owned by',
  [I18Keys.send]: 'Send',
  [I18Keys.likesNeeded]: '{{nLikes}} more likes needed to be public!',
  [I18Keys.likesNeededDetailed]:
    'This statement needs {{nLikes}} more likes to appear on the microdemocracy home page. Share it with others to get their support.',
  [I18Keys.joinA]: 'Join a',
  [I18Keys.projectAddress]: 'Project address',
  [I18Keys.leave]: 'Leave project',
  [I18Keys.accountValidatedNotChallenged]:
    'This account is valid and not currently challenged',
  [I18Keys.challenge]: 'challenge',
  [I18Keys.cantChallenge]: "Can't challenge",
  [I18Keys.pendingApplicationBy]: 'Pending application by {{by}}',
  [I18Keys.votedNofMEligible]: 'Voted {{nVoted}} of {{totalVoters}} eligible members',
  [I18Keys.preparingData]: 'Preparing your operation...',
  [I18Keys.approvingNewMember]: 'Approving new member',

  [I18Keys.justAMoment]: 'Just a moment...',
  [I18Keys.errorCreatingProject]: 'Error creating microdemocracy',
  [I18Keys.connectingUser]: 'Connecting user',
  [I18Keys.waitingSignature1]: 'Waiting for your signature 1/2...',
  [I18Keys.waitingSignature2]: 'Waiting for your signature 2/2...',
  [I18Keys.waitingIdentitySignature]: 'Waiting for anonymous identity signature...',
  [I18Keys.waitingIdentityOwnership]:
    'Waiting for anonymous identity confirmation signature...',
  [I18Keys.preparingIdentity]: 'Preparing your anonymous identity...',
  [I18Keys.waitingTransaction]: 'Sending operation to the network...',
  [I18Keys.operationSuccessful]: 'Onchain opperation successful!',
  [I18Keys.registeringProject]: 'Registering your microdemocracy...',
  [I18Keys.noLikesNeeded]:
    'This statement is already public in the microdemocracy homepage',
  [I18Keys.pleaseSignIn]: 'Please sign in to see your microdemocracies',
  [I18Keys.loadingProfile]: 'Loading you profile',
  [I18Keys.projectHome]: 'Back to Microdemocracy',
  [I18Keys.appHome]: 'Home',
  [I18Keys.backToMembers]: 'Back to Members',
  [I18Keys.exit]: 'exit',
  [I18Keys.alreadyBacked]: 'Member already backed this post',
  [I18Keys.cannotPublishStatement]: 'Cannot propose a statement',
  [I18Keys.cannotPublishStatementPeriod]:
    'Each member can only propose one statement per week',
  [I18Keys.done]: 'done',
  [I18Keys.language]: 'Language',
  [I18Keys.errorGeneratingProof]: 'Error generating cryptographic proof',
  [I18Keys.pleaseSignInToSee]: 'Please sign in to see your microdemocracies',
  [I18Keys.connectAndVote]: 'Connect to vote',
  [I18Keys.votedToRemove]: 'Voted to remove',
  [I18Keys.accountChallenged]: 'Account challenged',
  [I18Keys.voteNow]: 'Vote now',
  [I18Keys.alreadyVoted]: 'Already voted',
  [I18Keys.accountStatus]: 'Account status',
  [I18Keys.theFollowingStatementProposed]: 'The following statemet  was proposed',
  [I18Keys.forTheProject]: 'for the microdemocracy',
  [I18Keys.updateAvailable]: 'An update is available, ',
  [I18Keys.updateNow]: 'update now',
  [I18Keys.installPrompt]: 'Please install this app: ',
  [I18Keys.installNow]: 'install app',
}
