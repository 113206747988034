export enum I18Keys {
  appName = 's0001',
  project = 's0002',
  startNow = 's0003',
  startNew = 's0004',
  back = 's0005',
  next = 's0006',
  prev = 's0007',
  review = 's0008',
  create = 's0009',
  accept = 's000901',
  openApp = 's0010',
  invite = 's0011',
  inviteNew = 's0012',
  listOf = 's0013',
  noProjects = 's0014',
  yourProjects = 's0015',
  carousel01 = 's0016',
  carousel01sub = 's0017',
  carousel02 = 's0018',
  carousel02sub = 's0019',
  carousel03 = 's0020',
  carousel03sub = 's0021',
  carousel04 = 's0022',
  carousel04sub = 's0023',
  connectWallet = 's0024',
  connectWalletBtn = 's0025',
  notFound = 's0026',
  connectWithEmail = 's0027',
  connectWithEmailBtn = 's0028',
  accountReady = 's0029',
  connect = 's0030',
  loadingProject = 's0031',
  projectNotFound = 's0032',
  noStatements = 's0033',
  communityVoice = 's0034',
  projectHeader = 's0035',
  members = 's0036',
  member = 's0037',
  seeInviteMembers = 's0038',
  propose = 's0039',
  proposeNew = 's0040',
  statement = 's0041',
  proposeStatement = 's0042',
  proposeStatementBtn = 's0043',
  statementProposed = 's0044',
  connectToPropose = 's0045',
  proposeInfo = 's0046',
  newStatement = 's0047',
  sendingProposal = 's0048',
  onlyMembersCanInvite = 's0049',
  invitedBy = 's0050',
  founder = 's0051',
  accountInvalidated = 's0052',
  creatingProject = 's0053',
  examplesWho = 's0054',
  wantsTo = 's0055',
  whoTitle = 's0056',
  yourDetails = 's0057',
  connectAccount = 's0058',
  startA = 's0059',
  tryoutMsg = 's0060',
  IDNumber = 's0061',
  IDNumberLong = 's0062',
  toJoinMsg = 's0063',
  toJoinMsg2 = 's0064',
  selectMsgOpts = 's0065',
  nameAndLastName = 's0066',
  hideList = 's0067',
  and = 's0068',
  social = 's0069',
  account = 's0070',
  choosePlatformsMsg = 's0071',
  firstName = 's0072',
  lastName = 's0073',
  username = 's0074',
  connecting = 's0075',
  canJoin = 's0076',
  notWhoGiven = 's0077',
  anyoneWho = 's0078',
  foundedCommunity = 's0079',
  joinOurMsg = 's0080',
  close = 's0081',
  shareToInvite = 's0082',
  resettingMsg = 's0083',
  reset = 's0084',
  resetting = 's0085',
  shareLinkMsg = 's0086',
  linkCopied = 's0087',
  shareLink = 's0088',
  showQr = 's0089',
  scanQr = 's0090',
  cancel = 's0091',
  inviteTitle = 's0092',
  rememberInviteMsg = 's0093',
  loadingProjects = 's0094',
  connectedAs = 's0095',
  wallet = 's0096',
  owner = 's0097',
  logout = 's0098',
  helpEditable = 's0099',
  details = 's0100',
  options = 's0101',
  waitingTx = 's0102',
  loading = 's0103',
  askJoin = 's0104',
  you = 's0105',
  orUseQR = 's0106',
  resetLink = 's0107',
  joinProject = 's0108',
  personalDetailsPre = 's0109',
  personalDetailsMain = 's0110',
  reviewApplicationPre = 's0111',
  reviewApplicationMain = 's0112',
  applicationSentPre = 's0113',
  applicationSentMain = 's0114',
  applicationReceived = 's0115',
  projectSummary = 's0116',
  your = 's0117',
  approveNew = 's0118',
  askSupport = 's0119',
  applicationAcceptedAs = 's0120',
  important = 's0121',
  atLeastNMembers = 's0122',
  canBackN = 's0123',
  aStatementNeeds = 's0124',
  maxStatementsPerPeriod = 's0125',
  ownedBy = 's0126',
  send = 's0127',
  likesNeeded = 's0128',
  likesNeededDetailed = 's0129',
  joinA = 's0130',
  projectAddress = 's0131',
  leave = 's0132',
  accountValidatedNotChallenged = 's0133',
  challenge = 's0134',
  cantChallenge = 's0135',
  pendingApplicationBy = 's0136',
  votedNofMEligible = 's0137',
  preparingData = 's0138',
  approvingNewMember = 's0139',

  justAMoment = 's0141',
  errorCreatingProject = 's0142',
  connectingUser = 's0143',
  waitingSignature1 = 's0144',
  waitingSignature2 = 's0145',
  waitingIdentitySignature = 's0146',
  waitingIdentityOwnership = 's0147',
  preparingIdentity = 's0148',
  waitingTransaction = 's0149',
  operationSuccessful = 's0150',
  registeringProject = 's0151',
  noLikesNeeded = 's0152',
  pleaseSignIn = 's0153',
  loadingProfile = 's0154',
  projectHome = 's0155',
  appHome = 's0156',
  backToMembers = 's0157',
  exit = 's0158',
  alreadyBacked = 's0159',
  cannotPublishStatement = 's0160',
  cannotPublishStatementPeriod = 's0161',
  done = 's0162',
  language = 's0163',
  errorGeneratingProof = 's0164',
  pleaseSignInToSee = 's0165',
  connectAndVote = 's0166',
  votedToRemove = 's0167',
  accountChallenged = 's0168',
  voteNow = 's0169',
  alreadyVoted = 's0170',
  accountStatus = 's0171',
  theFollowingStatementProposed = 's0172',
  forTheProject = 's0173',
  updateAvailable = 's0174',
  updateNow = 's0175',
  installPrompt = 's0176',
  installNow = 's0177',
}
