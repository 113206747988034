import { I18Keys } from './kyel.list'

export const translation: Record<I18Keys, string> = {
  [I18Keys.appName]: 'microdemocracies',
  [I18Keys.project]: 'microdemocracia',
  [I18Keys.startNow]: 'iniciar una',
  [I18Keys.startNew]: 'començar nova',
  [I18Keys.back]: 'enrere',
  [I18Keys.next]: 'següent',
  [I18Keys.prev]: 'prev',
  [I18Keys.review]: 'revisar',
  [I18Keys.create]: 'crear',
  [I18Keys.accept]: 'aprovar',
  [I18Keys.openApp]: 'obrir app',
  [I18Keys.invite]: 'convidar',
  [I18Keys.inviteNew]: 'convida nous',
  [I18Keys.listOf]: 'Llista de',
  [I18Keys.noProjects]: 'Encara no has unit o iniciat cap microdemocracia.',
  [I18Keys.yourProjects]: 'Les teves microdemocracies:',
  [I18Keys.carousel01]: 'Troba alguna cosa que saps que <Bold>necessita</Bold> canviar',
  [I18Keys.carousel01sub]: 'Desafia altres a canviar-ho, <Bold>juntes</Bold>',
  [I18Keys.carousel02]: 'Parla <Bold>sense por</Bold>',
  [I18Keys.carousel02sub]:
    'Publica] <Bold>anònimament</Bold> al tauler de la comunitat, però només si ets membre',
  [I18Keys.carousel03]: 'Amplifica la teva <Bold>veu</Bold>',
  [I18Keys.carousel03sub]:
    "Dóna] suport <Bold>anònimament</Bold> a les declaracions amb les quals estiguis d'acord",
  [I18Keys.carousel04]: 'Recaptar i gastar fons de manera <Bold>transparent</Bold>',
  [I18Keys.carousel04sub]:
    'Controla] <Bold>col·lectivament</Bold> els fons de la comunitat, no es necessiten bancs. (properament)',
  [I18Keys.connectWallet]: 'Connectant',
  [I18Keys.connectWalletBtn]: 'Entrar',
  [I18Keys.notFound]: 'no trobat',
  [I18Keys.connectWithEmail]: 'Connecta amb el teu correu electrònic',
  [I18Keys.connectWithEmailBtn]: 'Inicia sessió amb correu electrònic',
  [I18Keys.accountReady]: 'Compte Preparat',
  [I18Keys.connect]: 'Connectar',
  [I18Keys.loadingProject]: 'Carregant detalls de la microdemocracia',
  [I18Keys.projectNotFound]: 'Projecte no trobat',
  [I18Keys.noStatements]: "Encara no s'ha dit res",
  [I18Keys.communityVoice]: 'Veu de la comunitat',
  [I18Keys.projectHeader]: 'microdemocracia per a',
  [I18Keys.members]: 'membres',
  [I18Keys.member]: 'membre',
  [I18Keys.seeInviteMembers]: 'Veure/Convidar Membres',
  [I18Keys.propose]: 'proposar',
  [I18Keys.proposeNew]: 'proposar',
  [I18Keys.statement]: 'declaració',
  [I18Keys.proposeStatement]: 'Proposar Declaració',
  [I18Keys.proposeStatementBtn]: 'Proposar Declaració',
  [I18Keys.statementProposed]: 'Declaració Proposada',
  [I18Keys.connectToPropose]: 'Connecta per proposar',
  [I18Keys.proposeInfo]:
    'Publiqueu] un nou missatge i vegeu quants membres de la comunitat el recolzen. Només els membres existents poden donar suport a la seva declaració. Assegureu-vos de convidar-los abans de fer la declaració',
  [I18Keys.newStatement]: 'Missatge',
  [I18Keys.sendingProposal]: 'enviant proposta anònima',
  [I18Keys.onlyMembersCanInvite]: 'Només els membres existents poden convidar a nous',
  [I18Keys.invitedBy]: 'convidat per',
  [I18Keys.founder]: 'Fundador',
  [I18Keys.accountInvalidated]: 'Compte invalidat',
  [I18Keys.creatingProject]: 'Creant la teva microdemocracia',
  [I18Keys.examplesWho]:
    '<]Bold>Exemples són:</Bold> "VIU A...", "ESTUDIA A...", "VOL...", etc.',
  [I18Keys.wantsTo]: 'Vol',
  [I18Keys.whoTitle]: 'Aquesta microdemocracia és per a la gent que',
  [I18Keys.yourDetails]: 'Els teus detalls',
  [I18Keys.connectAccount]: 'Connectar Compte',
  [I18Keys.startA]: 'Iniciar una',
  [I18Keys.tryoutMsg]:
    '<]Bold>NOMÉS PROVES!</Bold> Aquesta aplicació està actualment en desenvolupament. No l’utilitzis per crear comunitats reals encara.',
  [I18Keys.IDNumber]: "Document d'ID",
  [I18Keys.IDNumberLong]: "Document d'ID (Últims 4 dígits)",
  [I18Keys.toJoinMsg]:
    'Per] unir-se a la teva microdemocracia, els participants hauran de proporcionar el seu',
  [I18Keys.toJoinMsg2]: 'Se’ls demanarà als participants que proporcionin',
  [I18Keys.selectMsgOpts]: 'Selecciona o des-selecciona entre les següents opcions',
  [I18Keys.nameAndLastName]: 'Nom i Cognom',
  [I18Keys.hideList]: 'Amagar Llista',
  [I18Keys.and]: 'i',
  [I18Keys.social]: 'Xarxa social',
  [I18Keys.account]: 'compte',
  [I18Keys.choosePlatformsMsg]:
    'Trieu] el(s) compte(s) de la(s) plataforma(s) que se’ls demanarà als participants',
  [I18Keys.firstName]: 'Nom',
  [I18Keys.lastName]: 'Cognom',
  [I18Keys.username]: "nom d'usuari",
  [I18Keys.connecting]: 'connectant',
  [I18Keys.canJoin]: 'Pot unir-se qualsevol persona que',
  [I18Keys.notWhoGiven]: 'Has oblidat incloure qui pot unir-se',
  [I18Keys.anyoneWho]: 'La gent que',
  [I18Keys.foundedCommunity]: 'va fundar la comunitat',
  [I18Keys.joinOurMsg]: 'Uneix-te a la nostra microdemocracia!',
  [I18Keys.close]: 'Tancar',
  [I18Keys.shareToInvite]: 'Compartir per convidar',
  [I18Keys.resettingMsg]:
    "Reiniciar] l'enllaç invalidarà les invitacions enviades amb enllaços anteriors",
  [I18Keys.reset]: 'reiniciar enllaç',
  [I18Keys.resetting]: 'reiniciant...',
  [I18Keys.shareLinkMsg]:
    "Comparteix] l'enllaç amb nous membres o demana'ls que escanejin el codi QR",
  [I18Keys.linkCopied]: 'enllaç copiat!',
  [I18Keys.shareLink]: 'compartir enllaç',
  [I18Keys.showQr]: 'mostrar QR',
  [I18Keys.scanQr]: 'escanejar QR',
  [I18Keys.cancel]: 'cancel·lar',
  [I18Keys.inviteTitle]: 'Convidar a nou membre',
  [I18Keys.rememberInviteMsg]: 'Recorda, aquesta microdemocracia és per a la gent que',
  [I18Keys.loadingProjects]: 'Carregant',
  [I18Keys.connectedAs]: 'Connectat',
  [I18Keys.wallet]: 'Cartera',
  [I18Keys.owner]: 'Propietari',
  [I18Keys.logout]: 'sortir',
  [I18Keys.details]: 'detalls',
  [I18Keys.options]: 'opcions',
  [I18Keys.helpEditable]: 'Afegeix enllaços o estils bàsics ressaltant paraules',
  [I18Keys.waitingTx]: 'Enviant transació',
  [I18Keys.loading]: 'carregant',
  [I18Keys.askJoin]: `M'agradaria unir-me a la teva microdemocracia!`,
  [I18Keys.you]: 'tu',
  [I18Keys.orUseQR]: 'Utilitza un codi QR',
  [I18Keys.resetLink]: 'Reinicia enllaç',
  [I18Keys.joinProject]: 'Uneix-te',
  [I18Keys.personalDetailsPre]: 'Detalls',
  [I18Keys.personalDetailsMain]: 'personals',
  [I18Keys.applicationReceived]:
    "S]'ha rebut la teva sol·licitud. Espereu que sigui acceptat per la persona que us ha convidat, o compartiu el QR o l'enllaç següent amb un membre existent i demaneu-li que acceptin la vostra sol·licitud.",
  [I18Keys.projectSummary]: 'Resum de la teva microdemocracia',
  [I18Keys.your]: 'Les teves',
  [I18Keys.approveNew]: 'Aprova un nou',
  [I18Keys.askSupport]: 'Dona suport a la meva declaració',
  [I18Keys.applicationAcceptedAs]: 'Membre aceptat com a',
  [I18Keys.important]: 'Important!',
  [I18Keys.atLeastNMembers]:
    "Hi] ha d'haver almenys {{nMembers}} membres per proposar una declaració",
  [I18Keys.canBackN]:
    'Únicament] els {{nMembers}} membres actuals podran donar suport a aquesta declaració',
  [I18Keys.aStatementNeeds]:
    'Una] declaració necessita {{Likes}} like més per aparèixer a la pàgina de la microdemocràcia',
  [I18Keys.ownedBy]: 'Controlat per',
  [I18Keys.send]: 'Enviar',
  [I18Keys.likesNeeded]: 'Calen {{nLikes}} suports més per ser public!',
  [I18Keys.likesNeededDetailed]:
    'Aquesta] declaració necessita {{nLikes}} likes més per aparèixer a la pàgina de la microdemocràcia. Comparteix-la directament amb altres membres perquè la donin suport.',
  [I18Keys.joinA]: 'Uneix-te a una',
  [I18Keys.projectAddress]: 'Compte del projecte',
  [I18Keys.leave]: 'Abandonar',
  [I18Keys.accountValidatedNotChallenged]: 'Aquest compte és vàlid i no ha estat denunciat',
  [I18Keys.challenge]: 'denunciar',
  [I18Keys.cantChallenge]: 'No es pot denunciar-la',
  [I18Keys.pendingApplicationBy]: 'Solicitut de miembre par part de {{by}}',
  [I18Keys.votedNofMEligible]:
    'Han] votat: {{nVoted}} de {{totalVoters}} membres amb dret de vot',
  [I18Keys.preparingData]: "S'estan preparant les dades per enviar-les a la xarxa...",
  [I18Keys.approvingNewMember]: "Aprovació d'un nou membre",
  [I18Keys.reviewApplicationPre]: '',
  [I18Keys.reviewApplicationMain]: '',
  [I18Keys.applicationSentPre]: '',
  [I18Keys.applicationSentMain]: '',
  [I18Keys.maxStatementsPerPeriod]: '',
  [I18Keys.justAMoment]: '',
  [I18Keys.errorCreatingProject]: '',
  [I18Keys.connectingUser]: '',
  [I18Keys.waitingSignature1]: '',
  [I18Keys.waitingSignature2]: '',
  [I18Keys.waitingIdentitySignature]: '',
  [I18Keys.waitingIdentityOwnership]: '',
  [I18Keys.preparingIdentity]: '',
  [I18Keys.waitingTransaction]: '',
  [I18Keys.operationSuccessful]: '',
  [I18Keys.registeringProject]: '',
  [I18Keys.noLikesNeeded]: '',
  [I18Keys.pleaseSignIn]: '',
  [I18Keys.loadingProfile]: '',
  [I18Keys.projectHome]: '',
  [I18Keys.appHome]: '',
  [I18Keys.backToMembers]: '',
  [I18Keys.exit]: '',
  [I18Keys.alreadyBacked]: '',
  [I18Keys.cannotPublishStatement]: '',
  [I18Keys.cannotPublishStatementPeriod]: '',
  [I18Keys.done]: '',
  [I18Keys.language]: '',
  [I18Keys.errorGeneratingProof]: '',
  [I18Keys.pleaseSignInToSee]: '',
  [I18Keys.connectAndVote]: '',
  [I18Keys.votedToRemove]: '',
  [I18Keys.accountChallenged]: '',
  [I18Keys.voteNow]: '',
  [I18Keys.alreadyVoted]: '',
  [I18Keys.accountStatus]: '',
  [I18Keys.theFollowingStatementProposed]: '',
  [I18Keys.forTheProject]: '',
  [I18Keys.updateAvailable]: '',
  [I18Keys.updateNow]: '',
  [I18Keys.installPrompt]: '',
  [I18Keys.installNow]: '',
}
