import { I18Keys } from './kyel.list'

export const translation: Record<I18Keys, string> = {
  [I18Keys.appName]: 'microdemocracias',
  [I18Keys.project]: 'microdemocracia',
  [I18Keys.startNow]: 'crear microdemocracia',
  [I18Keys.startNew]: 'crear microdemocracia',
  [I18Keys.back]: 'atrás',
  [I18Keys.next]: 'siguiente',
  [I18Keys.prev]: 'atrás',
  [I18Keys.review]: 'revisar',
  [I18Keys.accept]: 'aprobar',
  [I18Keys.create]: 'crear',
  [I18Keys.openApp]: 'o abrir app',
  [I18Keys.invite]: 'invitar',
  [I18Keys.inviteNew]: 'invitas nuevoss',
  [I18Keys.listOf]: 'Lista de',
  [I18Keys.noProjects]: 'Aún no has unido o iniciado ninguna microdemocracia.',
  [I18Keys.yourProjects]: 'Microdemocracias:',
  [I18Keys.carousel01]: 'Encuentra algo que sabes que <Bold>necesita</Bold> cambiar',
  [I18Keys.carousel01sub]: 'Desafía a otros a cambiarlo, <Bold>juntos</Bold>',
  [I18Keys.carousel02]: 'Habla <Bold>sin miedo</Bold>',
  [I18Keys.carousel02sub]:
    'Publica <Bold>anónimamente</Bold> en el tablón de la comunidad, pero sólo si eres miembro',
  [I18Keys.carousel03]: 'Amplifica tu <Bold>voz</Bold>',
  [I18Keys.carousel03sub]:
    'Apoya <Bold>anónimamente</Bold> las declaraciones con las que estés de acuerdo',
  [I18Keys.carousel04]: 'Anonimidad <Bold>garantizada</Bold>',
  [I18Keys.carousel04sub]:
    ' <Bold>Nadie</Bold>, ni siquiera nosostro mismos!, podemos romper la anonimidad de una propuesta, publicar o dar like en nombre de otro miembro, o añadir o remover miembros de la microdemocracia.',
  [I18Keys.connectWallet]: 'Conectate',
  [I18Keys.connectWalletBtn]: 'Entrar',
  [I18Keys.notFound]: 'no encontrado',
  [I18Keys.connectWithEmail]: 'Conéctate con tu correo electrónico',
  [I18Keys.connectWithEmailBtn]: 'Iniciar sesión con correo electrónico',
  [I18Keys.accountReady]: 'Cuenta Lista',
  [I18Keys.connect]: 'Conectar',
  [I18Keys.loadingProject]: 'Cargando detalles de la microdemocracia',
  [I18Keys.projectNotFound]: 'Proyecto no encontrado',
  [I18Keys.noStatements]: 'Aún no se ha dicho nada',
  [I18Keys.communityVoice]: 'Voz de la comunidad',
  [I18Keys.projectHeader]: 'microdemocracia para',
  [I18Keys.members]: 'miembros',
  [I18Keys.member]: 'miembro',
  [I18Keys.seeInviteMembers]: 'Ver/Invitar Miembros',
  [I18Keys.propose]: 'proponer',
  [I18Keys.proposeNew]: 'proponer',
  [I18Keys.statement]: 'declaración',
  [I18Keys.proposeStatement]: 'Proponer declaración',
  [I18Keys.proposeStatementBtn]: 'Proponer declaración',
  [I18Keys.statementProposed]: 'Declaración propuesta',
  [I18Keys.connectToPropose]: 'Conectar para proponer',
  [I18Keys.proposeInfo]:
    'Publique] un nuevo mensage y vea cuántos miembros de la comunidad lo respaldan. Sólo los miembros existentes podrán respaldar su declaración. Asegurate de invitarlos antes de hacer la declaración',
  [I18Keys.newStatement]: 'Mensaje',
  [I18Keys.sendingProposal]: 'enviando propuesta anónima',
  [I18Keys.onlyMembersCanInvite]: 'Solo los miembros existentes pueden invitar a nuevos',
  [I18Keys.invitedBy]: 'invitado por',
  [I18Keys.founder]: 'Fundador',
  [I18Keys.accountInvalidated]: 'Cuenta invalidada',
  [I18Keys.creatingProject]: 'Creando tu microdemocracia',
  [I18Keys.examplesWho]:
    '<Bold>Algunos ejemplos serían frases que comiencen por:</Bold> "QUIERE...", "VIVE EN... ", "ESTUDIA EN...", etc.',
  [I18Keys.wantsTo]: 'Quiere',
  [I18Keys.whoTitle]: 'Esta microdemocracia es para gente que',
  [I18Keys.yourDetails]: 'Tus datos',
  [I18Keys.connectAccount]: 'Conectar Cuenta',
  [I18Keys.startA]: 'Iniciar una',
  [I18Keys.tryoutMsg]: 'Usar únicamente para pruebas. Aplicación en desarrollo. ',
  [I18Keys.IDNumber]: 'Documento de ID',
  [I18Keys.IDNumberLong]: 'Documento de ID (Últimos 4 dígitos)',
  [I18Keys.toJoinMsg]:
    'Para unirse a tu microdemocracia, los participantes deberán proporcionar su',
  [I18Keys.toJoinMsg2]: 'Cada miembro deberá proporcionar',
  [I18Keys.selectMsgOpts]: 'Selecciona o des-seleciona entre las siguientes opciones',
  [I18Keys.nameAndLastName]: 'Nombre y Apellido',
  [I18Keys.hideList]: 'Ocultar Lista',
  [I18Keys.and]: 'y',
  [I18Keys.social]: 'Red social',
  [I18Keys.account]: 'cuenta',
  [I18Keys.choosePlatformsMsg]:
    'Elige la(s) cuenta(s) de la(s) plataforma(s) que se pedirá a los participantes',
  [I18Keys.firstName]: 'Nombre',
  [I18Keys.lastName]: 'Apellido',
  [I18Keys.username]: 'nombre de usuario',
  [I18Keys.connecting]: 'conectando',
  [I18Keys.canJoin]: 'Podrá unirse cualquier persona que',
  [I18Keys.notWhoGiven]: 'Olvidaste incluir quién puede unirse',
  [I18Keys.anyoneWho]: 'Gente que',
  [I18Keys.foundedCommunity]: 'fundó la comunidad',
  [I18Keys.joinOurMsg]: `'Únete a nuestra microdemocracia!`,
  [I18Keys.close]: 'Cerrar',
  [I18Keys.shareToInvite]: 'Compartir para invitar',
  [I18Keys.resettingMsg]:
    'Resetear el enlace invalidará las invitaciones enviadas con enlaces anteriores',
  [I18Keys.reset]: 'resetear enlace',
  [I18Keys.resetting]: 'reseteando...',
  [I18Keys.shareLinkMsg]:
    'Comparte el enlace con nuevos miembros o pídeles que escaneen el codigo QR',
  [I18Keys.linkCopied]: '¡enlace copiado!',
  [I18Keys.shareLink]: 'compartir enlace',
  [I18Keys.showQr]: 'mostrar QR',
  [I18Keys.scanQr]: 'escanear QR',
  [I18Keys.cancel]: 'cancelar',
  [I18Keys.inviteTitle]: 'Invitar a nuevo miembro',
  [I18Keys.rememberInviteMsg]: 'Recuerda, esta microdemocracia es para gente que',
  [I18Keys.connectedAs]: 'Conectado',
  [I18Keys.wallet]: 'Wallet',
  [I18Keys.owner]: 'Owner',
  [I18Keys.logout]: 'salir',
  [I18Keys.helpEditable]: 'Añade enlaces o estilos básicos seleccionando una palabra',
  [I18Keys.details]: 'detalles',
  [I18Keys.options]: 'opciones',
  [I18Keys.waitingTx]: 'Enviando transacción',
  [I18Keys.loading]: 'cargando',
  [I18Keys.askJoin]: `Me gustaría unirme a tu microdemocracia!`,
  [I18Keys.you]: 'tú',
  [I18Keys.orUseQR]: 'Utiliza un código QR',
  [I18Keys.resetLink]: 'Resetea enlace',
  [I18Keys.joinProject]: 'Unirse',
  [I18Keys.personalDetailsPre]: 'Detalles',
  [I18Keys.personalDetailsMain]: 'personales',
  [I18Keys.applicationReceived]:
    'Su solicitud fue recibida. Espere a que sea aceptado por la persona que lo invitó, o comparta el QR o el enlace a continuación con un miembro existente y pídale que acepte su solicitud.',
  [I18Keys.projectSummary]: 'Resumen de tu microdemocracia',
  [I18Keys.your]: 'Tus',
  [I18Keys.approveNew]: 'Aprueba un nuevo',
  [I18Keys.askSupport]: 'Apoya mi declaración',
  [I18Keys.applicationAcceptedAs]: 'Miembro aceptado como',
  [I18Keys.important]: 'Importante!',
  [I18Keys.atLeastNMembers]:
    'Debe haber al menos {{nMembers}} miembros para proponer una declaración',
  [I18Keys.canBackN]:
    'Únicamente los {{nMembers}} miembros actuales podrán apoyar esta declaración',
  [I18Keys.aStatementNeeds]:
    'Una declaración necesita {{nLikes}} like más para aparecer en la página de la microdemocracia',
  [I18Keys.ownedBy]: 'Controlada por',
  [I18Keys.send]: 'Enviar',
  [I18Keys.likesNeeded]: 'Hacen falta {{nLikes}} apoyos más para que sea público!',
  [I18Keys.likesNeededDetailed]:
    'Esta declaración necesita {{nLikes}} likes más para aparecer en la página de la microdemocracia. Compártela directamente con otros miembros para que la apoyen.',
  [I18Keys.joinA]: 'Únete a una',
  [I18Keys.projectAddress]: 'Cuenta del proyecto',
  [I18Keys.leave]: 'Abandonar',
  [I18Keys.accountValidatedNotChallenged]: 'Esta cuenta es valida y no ha sido denunciada',
  [I18Keys.challenge]: 'denunciar',
  [I18Keys.cantChallenge]: 'No puedes denunciarla',
  [I18Keys.pendingApplicationBy]: 'Solicitud de miembro por parte de {{by}}',
  [I18Keys.votedNofMEligible]:
    'Han votado: {{nVoted}} de {{totalVoters}} miembros con derecho a voto',
  [I18Keys.preparingData]: 'Preparando los datos a enviar a la red...',
  [I18Keys.approvingNewMember]: 'Aprobando a un nuevo miembro',
  [I18Keys.connectingUser]: 'Connecting user',
  [I18Keys.pleaseSignIn]: 'Por favor inicia sesión para ver tus microdemocracias',
  [I18Keys.justAMoment]: 'Un momento por favor...',
  [I18Keys.waitingTransaction]: 'Enviando transacción a la red...',
  [I18Keys.registeringProject]: 'Registrando tu microdemocracia...',
  [I18Keys.loadingProjects]: 'Cargando tus microdemocracias',
  [I18Keys.reviewApplicationPre]: 'Revisar',
  [I18Keys.reviewApplicationMain]: 'tu aplicación',
  [I18Keys.applicationSentPre]: 'Aplicación',
  [I18Keys.applicationSentMain]: 'enviada',
  [I18Keys.maxStatementsPerPeriod]:
    'Cada miembro puede publicar una declaración por semana',
  [I18Keys.errorCreatingProject]: 'Error creando la microdemocracia',
  [I18Keys.waitingSignature1]: 'Esperando firma 1/2...',
  [I18Keys.waitingSignature2]: 'Esperando firma 2/2...',
  [I18Keys.waitingIdentitySignature]: 'Esperando firma de identidad anónima...',
  [I18Keys.waitingIdentityOwnership]:
    'Esperando firma de confirmación de identidad anónima...',
  [I18Keys.preparingIdentity]: 'Preparando identidad anónima...',
  [I18Keys.operationSuccessful]: 'Operación exitosa!',
  [I18Keys.noLikesNeeded]:
    'Propuesta publicada en la pagina principal de la microdemocracia',
  [I18Keys.loadingProfile]: 'Carcando tu perfil',
  [I18Keys.projectHome]: 'Volver a microdemocracia',
  [I18Keys.appHome]: 'todas',
  [I18Keys.backToMembers]: 'Volver a miembros',
  [I18Keys.exit]: 'Salir',
  [I18Keys.alreadyBacked]: 'Ya has apoyado esta declaración',
  [I18Keys.cannotPublishStatement]: 'No puedes publicar la declaración',
  [I18Keys.cannotPublishStatementPeriod]:
    'Cada miembro solo puede publicar una declaración por semana',
  [I18Keys.done]: 'Volver',
  [I18Keys.language]: 'Idioma',
  [I18Keys.errorGeneratingProof]: 'Error generando prueba criptográfica',
  [I18Keys.pleaseSignInToSee]: 'Inicia sesión para ver tus microdemocracias',
  [I18Keys.connectAndVote]: 'Inicia sesión',
  [I18Keys.votedToRemove]: 'Votaron a favor de remover',
  [I18Keys.accountChallenged]: 'Cuenta denunciada',
  [I18Keys.voteNow]: 'Vota ahora',
  [I18Keys.alreadyVoted]: 'Ya has votado',
  [I18Keys.accountStatus]: 'Estado de la cuenta',
  [I18Keys.theFollowingStatementProposed]: 'La siguiente declaración fue propuesta:',
  [I18Keys.forTheProject]: 'Para la microdemocracia',
  [I18Keys.updateAvailable]: 'Actualización disponible, ',
  [I18Keys.updateNow]: 'actualizar',
  [I18Keys.installPrompt]: 'Instala esta app',
  [I18Keys.installNow]: 'instalar',
}
